<template>
    <svg width="79"
         height="20"
         viewBox="0 0 79 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M3.0867 0.331055H0V18.8162H8.88444V16.0963H3.0867V0.331055Z" fill="white"/>
        <path d="M19.7819 8.17392C19.388 7.55775 18.829 7.07382 18.1043 6.72156C17.3798 6.36949 16.6504 6.19336 15.9169 6.19336C14.968 6.19336 14.1004 6.36506 13.3131 6.70828C12.5255 7.0515 11.8501 7.5229 11.2864 8.12118C10.7228 8.71983 10.2841 9.41088 9.97124 10.194C9.65781 10.9776 9.50146 11.8009 9.50146 12.6633C9.50146 13.5437 9.65781 14.3711 9.97124 15.1455C10.2841 15.9204 10.7228 16.607 11.2864 17.2053C11.8501 17.804 12.5254 18.2748 13.3131 18.618C14.1002 18.9613 14.968 19.133 15.9169 19.133C16.6504 19.133 17.3798 18.9568 18.1043 18.605C18.829 18.2531 19.3882 17.7689 19.7819 17.1526V18.8163H22.7076V6.51039H19.7819V8.17392ZM19.5136 14.1684C19.3346 14.6438 19.0842 15.0575 18.7621 15.4096C18.4399 15.7618 18.0507 16.0434 17.5944 16.2546C17.138 16.466 16.6322 16.5715 16.0777 16.5715C15.5228 16.5715 15.0218 16.466 14.5745 16.2546C14.127 16.0433 13.7425 15.7618 13.4205 15.4096C13.0982 15.0575 12.8521 14.6438 12.6823 14.1684C12.5119 13.6931 12.4273 13.1913 12.4273 12.6633C12.4273 12.1351 12.5119 11.6332 12.6823 11.158C12.8519 10.6827 13.0982 10.2692 13.4205 9.91695C13.7425 9.56506 14.1272 9.28307 14.5745 9.0719C15.0218 8.86055 15.5228 8.75505 16.0777 8.75505C16.6322 8.75505 17.138 8.86055 17.5944 9.0719C18.0507 9.28326 18.4399 9.56506 18.7621 9.91695C19.0842 10.2692 19.3346 10.6827 19.5136 11.158C19.6923 11.6332 19.7821 12.1351 19.7821 12.6633C19.7819 13.1913 19.6923 13.6931 19.5136 14.1684Z" fill="white"/>
        <path d="M43.5632 8.17392C43.1693 7.55775 42.6103 7.07382 41.8856 6.72156C41.1611 6.36949 40.4317 6.19336 39.6981 6.19336C38.7492 6.19336 37.8816 6.36506 37.0943 6.70828C36.3068 7.0515 35.6314 7.5229 35.0677 8.12118C34.504 8.71983 34.0654 9.41088 33.7525 10.194C33.4391 10.9776 33.2827 11.8009 33.2827 12.6633C33.2827 13.5437 33.4391 14.3711 33.7525 15.1455C34.0654 15.9204 34.504 16.607 35.0677 17.2053C35.6314 17.804 36.3066 18.2748 37.0943 18.618C37.8815 18.9613 38.7492 19.133 39.6981 19.133C40.4317 19.133 41.1611 18.9568 41.8856 18.605C42.6103 18.2531 43.1695 17.7689 43.5632 17.1526V18.8163H46.4888V6.51039H43.5632V8.17392ZM43.2947 14.1684C43.1157 14.6438 42.8652 15.0575 42.5432 15.4096C42.221 15.7618 41.8318 16.0434 41.3755 16.2546C40.919 16.466 40.4133 16.5715 39.8588 16.5715C39.3039 16.5715 38.8028 16.466 38.3555 16.2546C37.9081 16.0433 37.5236 15.7618 37.2015 15.4096C36.8793 15.0575 36.6332 14.6438 36.4633 14.1684C36.2929 13.6931 36.2084 13.1913 36.2084 12.6633C36.2084 12.1351 36.2929 11.6332 36.4633 11.158C36.633 10.6827 36.8793 10.2692 37.2015 9.91695C37.5236 9.56506 37.9083 9.28307 38.3555 9.0719C38.8028 8.86055 39.3039 8.75505 39.8588 8.75505C40.4133 8.75505 40.919 8.86055 41.3755 9.0719C41.8318 9.28326 42.221 9.56506 42.5432 9.91695C42.8652 10.2692 43.1157 10.6827 43.2947 11.158C43.4734 11.6332 43.5632 12.1351 43.5632 12.6633C43.5632 13.1913 43.4734 13.6931 43.2947 14.1684Z" fill="white"/>
        <path d="M78.5842 0.331055H75.6587V18.8162H78.5842V0.331055Z" fill="white"/>
        <path d="M25.0156 18.8165H27.9413V9.34281H32.9607V6.51074H25.0156V18.8165Z" fill="white"/>
        <path d="M58.2186 6.51074L54.5414 15.9342L50.8642 6.51074H47.9009L52.703 18.8165H56.3798L61.182 6.51074H58.2186Z" fill="white"/>
        <path d="M67.5634 6.19385C63.9807 6.19385 61.145 9.09047 61.145 12.6634C61.145 16.6131 63.8896 19.1329 67.9384 19.1329C70.2044 19.1329 71.6512 18.2805 73.4186 16.424L71.442 14.9195C71.4409 14.921 69.9502 16.8466 67.7243 16.8466C65.137 16.8466 64.0478 14.7944 64.0478 13.7327H73.7539C74.2638 9.66736 71.5472 6.19385 67.5634 6.19385ZM64.0555 11.5939C64.078 11.3571 64.4149 8.48001 67.5402 8.48001C70.6653 8.48001 71.0451 11.3567 71.0669 11.5939H64.0555Z" fill="white"/>
    </svg>
</template>
