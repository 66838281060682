import { onBeforeUpdate, Ref, shallowRef } from 'vue'

export function useLoopReferences<T>() {

    const references: Ref<T[]> = shallowRef([])

    onBeforeUpdate(() => {

        references.value = []

    })

    return {
        references,
        setReference(element: T) {

            references.value.push(element)

        }
    }

}
