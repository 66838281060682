<template>
    <svg width="126" height="63" viewBox="0 0 126 63" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.6066 46.8643L38.5165 28.9502H25.4937L14.0972 40.3508L20.6066 46.8643Z" fill="white"/>
<path d="M4.32715 30.5808L25.4939 9.41406H38.5167L10.8406 37.0943L4.32715 30.5808Z" fill="white"/>
<path d="M25.4938 51.7471H38.5167L27.1201 40.3506L20.6067 46.8641L25.4938 51.7471Z" fill="white"/>
<path d="M27.1201 40.3506L20.6067 46.8641L30.2668 43.5176L27.1201 40.3506Z" fill="url(#paint0_linear_3106_3221)"/>
<path d="M14.0972 40.3465L20.6106 33.833L27.1241 40.3465L20.6106 46.864L14.0972 40.3465Z" fill="white"/>
<path d="M38.5167 28.9504L27.1202 40.351L38.5167 51.7516H25.4939L20.6067 46.8645L14.0933 40.351L25.4939 28.9504H38.5167ZM25.4939 9.41406L4.32715 30.5808L10.8406 37.0943L38.5167 9.41406H25.4939V9.41406Z" fill="url(#paint1_radial_3106_3221)"/>
<path d="M42.6406 20.7627H54.0544V22.9367H45.0321V29.6763H53.1848V31.8504H45.0321V40.3292H42.7493V20.7627H42.6406Z" fill="white"/>
<path d="M56.772 20.7627H59.0547V40.4379H56.772V20.7627Z" fill="white"/>
<path d="M67.6423 40.8725C65.903 40.8725 64.7073 40.329 63.729 39.3506C62.8594 38.3723 62.4246 36.9592 62.4246 35.2199V26.415H64.7073V34.8938C64.7073 36.1983 65.0334 37.1766 65.5769 37.8288C66.2291 38.481 66.9901 38.8071 68.0771 38.8071C68.838 38.8071 69.5989 38.5897 70.1424 38.1549C70.7947 37.7201 71.2295 37.1766 71.5556 36.5244C71.8817 35.8722 72.0991 35.1112 72.0991 34.3503V26.415H74.3818V40.4377H72.2078V38.3723H72.0991C71.6643 39.0245 71.1208 39.6768 70.2511 40.1116C69.4902 40.6551 68.6206 40.8725 67.6423 40.8725Z" fill="white"/>
<path d="M83.5129 40.6553C82.9694 40.6553 82.4258 40.5466 81.8823 40.3292C81.4475 40.1118 81.0127 39.8944 80.6866 39.5683C80.3605 39.2421 80.0344 38.8073 79.817 38.3725C79.5996 37.9377 79.4909 37.3942 79.4909 36.742V28.4806H76.9907V26.4152H79.4909V22.502H81.7736V26.4152H85.1434V28.4806H81.7736V36.0898C81.7736 36.8507 81.8823 37.3942 82.2084 37.829C82.5346 38.2638 83.0781 38.4812 83.7303 38.4812C84.2738 38.4812 84.8173 38.3725 85.2521 38.0464V40.3292C84.926 40.4379 84.7086 40.5466 84.3825 40.6553C84.3825 40.6553 83.9477 40.6553 83.5129 40.6553Z" fill="white"/>
<path d="M93.4046 40.6553C92.8611 40.6553 92.3176 40.5466 91.7741 40.3292C91.3393 40.1118 90.9044 39.8944 90.5783 39.5683C90.2522 39.2421 89.9261 38.8073 89.7087 38.3725C89.4913 37.9377 89.3826 37.3942 89.3826 36.742V28.4806H87.0999V26.4152H89.6V22.502H91.8828V26.4152H95.2525V28.4806H91.8828V36.0898C91.8828 36.8507 91.9915 37.3942 92.3176 37.829C92.6437 38.2638 93.1872 38.4812 93.8394 38.4812C94.3829 38.4812 94.9264 38.3725 95.3612 38.0464V40.3292C95.0351 40.4379 94.8177 40.5466 94.4916 40.6553C94.2742 40.6553 93.8394 40.6553 93.4046 40.6553Z" fill="white"/>
<path d="M103.731 40.8724C102.427 40.8724 101.231 40.5463 100.144 39.8941C99.0572 39.2419 98.2962 38.3723 97.644 37.2852C97.1005 36.1982 96.7744 34.8938 96.7744 33.4806C96.7744 32.1762 97.1005 30.8718 97.644 29.7848C98.1875 28.6977 99.0572 27.7194 100.035 27.0672C101.014 26.415 102.21 26.0889 103.623 26.0889C105.036 26.0889 106.232 26.415 107.21 27.0672C108.188 27.7194 108.949 28.4803 109.493 29.5673C110.036 30.6544 110.362 31.8501 110.362 33.2632C110.362 33.5893 110.362 33.8067 110.254 34.0242H99.0572C99.1659 35.1112 99.3833 35.9808 99.8181 36.7417C100.253 37.5026 100.905 38.0461 101.557 38.3723C102.318 38.6984 102.97 38.9158 103.731 38.9158C105.579 38.9158 106.884 38.0461 107.862 36.4156L109.819 37.3939C109.167 38.481 108.406 39.4593 107.319 40.1115C106.449 40.5463 105.253 40.8724 103.731 40.8724ZM107.753 31.9588C107.753 31.4153 107.536 30.7631 107.21 30.2196C106.884 29.6761 106.449 29.1325 105.797 28.6977C105.145 28.2629 104.384 28.0455 103.405 28.0455C102.318 28.0455 101.34 28.3716 100.579 29.1325C99.8181 29.7848 99.3833 30.7631 99.0572 31.9588H107.753Z" fill="white"/>
<path d="M112.971 26.415H115.145V28.6977H115.254C115.58 27.9368 116.015 27.2846 116.884 26.8498C117.645 26.3063 118.515 26.0889 119.385 26.0889C120.037 26.0889 120.58 26.1976 121.015 26.415V28.9151C120.472 28.589 119.819 28.4803 119.059 28.4803C118.406 28.4803 117.754 28.6977 117.211 29.0238C116.667 29.4586 116.232 29.8935 115.797 30.5457C115.471 31.1979 115.254 31.8501 115.254 32.611V40.4376H112.971V26.415Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_3106_3221" x1="-1017.43" y1="-448.268" x2="-1017.24" y2="-448.457" gradientUnits="userSpaceOnUse">
<stop stop-color="#1A237E" stop-opacity="0.4"/>
<stop offset="1" stop-color="#1A237E" stop-opacity="0"/>
</linearGradient>
<radialGradient id="paint1_radial_3106_3221" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-929.504 -371.392) scale(2.01062 2.01063)">
<stop stop-color="white" stop-opacity="0.1"/>
<stop offset="1" stop-color="white" stop-opacity="0"/>
</radialGradient>
</defs>
</svg>

</template>
