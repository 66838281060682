<template>
    <svg width="58" height="59" viewBox="0 0 58 59" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3109_3473)">
            <path
                d="M14.7963 58.9999C14.7358 58.9999 14.6743 58.9914 14.6147 58.9741C14.3902 58.9086 14.2169 58.7274 14.1608 58.4985L0.0188872 0.817394C-0.0372386 0.588977 0.0321452 0.347648 0.200522 0.184239C0.3689 0.0208304 0.61108 -0.0392792 0.835583 0.0257282L57.5266 16.4699C57.7515 16.5349 57.9248 16.7162 57.9809 16.945C58.037 17.1739 57.9681 17.4157 57.7993 17.5786L15.2502 58.8156C15.1264 58.9353 14.9634 58.9999 14.7963 58.9999V58.9999ZM1.56124 1.60861L15.1596 57.0728L56.0739 17.421L1.56124 1.60861Z"
                fill="white" />
            <path
                d="M36.0553 38.3916C35.9948 38.3916 35.9338 38.3831 35.8742 38.3658L7.54435 30.141C7.31984 30.0756 7.14661 29.8948 7.09048 29.6659C7.03435 29.4371 7.1033 29.1957 7.27167 29.0323L28.5358 8.40806C28.7042 8.24465 28.9468 8.1841 29.1713 8.24955C29.3963 8.31501 29.5691 8.49622 29.6252 8.72509L36.6909 37.5741C36.747 37.8025 36.6776 38.0439 36.5092 38.2073C36.3855 38.327 36.2224 38.3916 36.0553 38.3916ZM8.99655 29.1899L35.1489 36.7825L28.626 10.1512L8.99655 29.1899Z"
                fill="white" />
            <path
                d="M25.4713 48.4208C25.4112 48.4208 25.3502 48.4123 25.291 48.395L11.2163 44.3244C10.9913 44.2594 10.8181 44.0787 10.7615 43.8498C10.7049 43.6209 10.7739 43.3796 10.9423 43.2158L21.2367 33.2184L17.9107 19.6368L4.19391 15.6695C3.96896 15.6045 3.79573 15.4233 3.73916 15.1949C3.68259 14.966 3.75153 14.7247 3.91991 14.5609L14.4853 4.30082C14.6536 4.13741 14.8962 4.07685 15.1208 4.14186C15.3457 4.20731 15.5185 4.38853 15.5746 4.6174L18.9886 18.5588L32.3765 22.4308L42.6334 12.4699C42.8018 12.3065 43.0444 12.246 43.2689 12.311C43.4939 12.3764 43.6667 12.5576 43.7228 12.7865L47.2322 27.1175C47.2883 27.3459 47.2189 27.5873 47.0506 27.7507C46.8822 27.9141 46.6404 27.9746 46.4159 27.9096L32.67 23.9339L22.6845 33.6316L26.1059 47.6033C26.1621 47.8317 26.0927 48.0731 25.9243 48.2365C25.8006 48.3562 25.6375 48.4208 25.4704 48.4208H25.4713ZM12.6676 43.372L24.5649 46.813L21.5982 34.6989L12.6676 43.372V43.372ZM19.3567 20.0375L22.3234 32.1516L31.254 23.4784L19.3567 20.0375V20.0375ZM33.7934 22.8871L45.6907 26.3281L42.724 14.214L33.7934 22.8871ZM5.64567 14.7176L17.543 18.1585L14.5763 6.04444L5.64567 14.7176Z"
                fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_3109_3473">
                <rect width="58" height="59" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
