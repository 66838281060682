<template>
    <svg width="94" height="63" viewBox="0 0 94 63" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M45.5815 59.448C45.2978 59.448 45.033 59.3695 44.7871 59.2321L42.2716 57.6817C41.8934 57.4658 42.0825 57.3873 42.196 57.348C42.7066 57.1714 42.8012 57.1321 43.3308 56.8181C43.3875 56.7789 43.4632 56.7985 43.5199 56.8378L45.4491 58.035C45.5248 58.0742 45.6193 58.0742 45.6761 58.035L53.2225 53.5013C53.2982 53.4621 53.336 53.3836 53.336 53.2854V44.2378C53.336 44.1396 53.2982 44.0611 53.2225 44.0219L45.6761 39.5079C45.6004 39.4686 45.5058 39.4686 45.4491 39.5079L37.9026 44.0219C37.827 44.0611 37.7891 44.1593 37.7891 44.2378V53.2854C37.7891 53.3639 37.827 53.4621 37.9026 53.5013L39.9642 54.7378C41.0801 55.3265 41.7799 54.6396 41.7799 53.9527V45.0228C41.7799 44.905 41.8745 44.7873 42.0069 44.7873H42.9714C43.0849 44.7873 43.1984 44.8854 43.1984 45.0228V53.9527C43.1984 55.5032 42.3851 56.406 40.9666 56.406C40.5316 56.406 40.1912 56.406 39.2266 55.9153L37.2407 54.7378C36.7489 54.4434 36.4463 53.8938 36.4463 53.305V44.2574C36.4463 43.6686 36.7489 43.1191 37.2407 42.8247L44.7871 38.291C45.26 38.0163 45.903 38.0163 46.3759 38.291L53.9223 42.8247C54.4141 43.1191 54.7167 43.6686 54.7167 44.2574V53.305C54.7167 53.8938 54.4141 54.4434 53.9223 54.7378L46.3759 59.2714C46.13 59.3892 45.8463 59.448 45.5815 59.448Z"
            fill="white" />
        <path
            d="M47.908 53.2267C44.5981 53.2267 43.9172 51.6566 43.9172 50.322C43.9172 50.2043 44.0118 50.0865 44.1442 50.0865H45.1277C45.2412 50.0865 45.3357 50.165 45.3357 50.2828C45.4871 51.323 45.9221 51.8333 47.9269 51.8333C49.5156 51.8333 50.1965 51.4604 50.1965 50.5772C50.1965 50.0669 50.0074 49.694 47.5297 49.4389C45.4681 49.223 44.182 48.7519 44.182 47.0445C44.182 45.4548 45.4681 44.5127 47.6243 44.5127C50.0452 44.5127 51.2368 45.3762 51.3881 47.2604C51.3881 47.3192 51.3692 47.3781 51.3313 47.437C51.2935 47.4762 51.2368 47.5155 51.18 47.5155H50.1965C50.1019 47.5155 50.0074 47.437 49.9885 47.3389C49.7615 46.2594 49.1752 45.9062 47.6243 45.9062C45.8842 45.9062 45.6762 46.5342 45.6762 47.0052C45.6762 47.5744 45.9221 47.751 48.2673 48.065C50.5937 48.379 51.6907 48.8305 51.6907 50.5183C51.6718 52.2454 50.31 53.2267 47.908 53.2267Z"
            fill="white" />
        <path
            d="M58.8021 43.727C58.8021 44.5513 58.1401 45.2382 57.3457 45.2382C56.5514 45.2382 55.8894 44.571 55.8894 43.727C55.8894 42.8635 56.5703 42.2158 57.3457 42.2158C58.1401 42.2158 58.8021 42.8831 58.8021 43.727ZM56.1164 43.727C56.1164 44.4336 56.6649 45.0027 57.3268 45.0027C58.0077 45.0027 58.5562 44.4139 58.5562 43.727C58.5562 43.0205 58.0077 42.471 57.3268 42.471C56.6838 42.471 56.1164 43.0205 56.1164 43.727ZM56.7972 42.8831H57.3647C57.5538 42.8831 57.9321 42.8831 57.9321 43.3345C57.9321 43.6485 57.7429 43.7074 57.6294 43.7467C57.8564 43.7663 57.8753 43.9233 57.8942 44.1392C57.9131 44.2766 57.9321 44.5121 57.9699 44.5906H57.6294C57.6294 44.5121 57.5727 44.0803 57.5727 44.0607C57.5538 43.9625 57.516 43.9233 57.4025 43.9233H57.1188V44.6102H56.7972V42.8831ZM57.0999 43.6289H57.3457C57.5538 43.6289 57.5916 43.4719 57.5916 43.3934C57.5916 43.1579 57.4403 43.1579 57.3457 43.1579H57.0809V43.6289H57.0999Z"
            fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M22.1667 24.0613C22.1667 23.708 21.9775 23.3744 21.6749 23.1977L13.6745 18.3893C13.5421 18.3108 13.3908 18.2716 13.2395 18.252C13.2206 18.252 13.1638 18.252 13.1638 18.252C13.0125 18.252 12.8612 18.3108 12.7288 18.3893L4.70952 23.1781C4.40691 23.3548 4.21777 23.6884 4.21777 24.0417L4.23669 36.9164C4.23669 37.0931 4.33125 37.2697 4.48256 37.3482C4.63387 37.4463 4.82301 37.4463 4.9554 37.3482L9.7216 34.5417C10.0242 34.365 10.2133 34.0314 10.2133 33.6781V27.6529C10.2133 27.2996 10.4025 26.966 10.7051 26.7893L12.7288 25.5725C12.8801 25.4744 13.0504 25.4351 13.2206 25.4351C13.3908 25.4351 13.561 25.4744 13.6934 25.5725L15.7172 26.7893C16.0198 26.966 16.2089 27.2996 16.2089 27.6529V33.6781C16.2089 34.0314 16.3981 34.365 16.7007 34.5417L21.4669 37.3678C21.6182 37.466 21.8073 37.466 21.9586 37.3678C22.1099 37.2893 22.2045 37.1127 22.2045 36.9361L22.1667 24.0613Z"
            fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M60.7123 6.33915C60.561 6.26065 60.3719 6.26065 60.2395 6.33915C60.0882 6.43728 59.9936 6.59429 59.9936 6.77093V19.5279C59.9936 19.6457 59.9369 19.7634 59.8234 19.8419C59.7099 19.9008 59.5964 19.9008 59.4829 19.8419L57.4781 18.6448C57.1755 18.4681 56.8161 18.4681 56.5135 18.6448L48.4942 23.4532C48.1916 23.6298 48.0024 23.9634 48.0024 24.3167V33.9139C48.0024 34.2672 48.1916 34.6008 48.4942 34.7775L56.5135 39.5859C56.8161 39.7625 57.1755 39.7625 57.4781 39.5859L65.4974 34.7775C65.8 34.6008 65.9892 34.2672 65.9892 33.9139V9.98962C65.9892 9.61672 65.8 9.28308 65.4974 9.10644L60.7123 6.33915ZM59.9747 30.7541C59.9747 30.8522 59.9369 30.9307 59.8612 30.97L57.1187 32.6186C57.0431 32.6578 56.9485 32.6578 56.8729 32.6186L54.1304 30.97C54.0548 30.9307 54.0169 30.8326 54.0169 30.7541V27.4569C54.0169 27.3588 54.0548 27.2803 54.1304 27.241L56.8729 25.5924C56.9485 25.5532 57.0431 25.5532 57.1187 25.5924L59.8612 27.241C59.9369 27.2803 59.9747 27.3784 59.9747 27.4569V30.7541Z"
            fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M87.3992 27.3784C87.7018 27.2018 87.872 26.8681 87.872 26.5149V24.1793C87.872 23.8261 87.6829 23.4924 87.3992 23.3158L79.4366 18.527C79.134 18.3504 78.7746 18.3504 78.472 18.527L70.4527 23.3354C70.1501 23.5121 69.9609 23.8457 69.9609 24.199V33.7962C69.9609 34.1494 70.1501 34.4831 70.4527 34.6597L78.4153 39.37C78.7179 39.5466 79.0772 39.5466 79.3609 39.37L84.1839 36.5831C84.3352 36.5046 84.4297 36.3279 84.4297 36.1513C84.4297 35.9747 84.3352 35.798 84.1839 35.7195L76.1267 30.9111C75.9754 30.813 75.8809 30.656 75.8809 30.4793V27.4765C75.8809 27.2999 75.9754 27.1233 76.1267 27.0448L78.6422 25.5532C78.7935 25.455 78.9827 25.455 79.134 25.5532L81.6495 27.0448C81.8008 27.1429 81.8953 27.2999 81.8953 27.4765V29.8317C81.8953 30.0083 81.9899 30.185 82.1412 30.2635C82.2925 30.3616 82.4817 30.3616 82.633 30.2635L87.3992 27.3784Z"
            fill="white" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M78.8314 26.9269C78.8881 26.8876 78.9638 26.8876 79.0205 26.9269L80.5525 27.8493C80.6093 27.8886 80.6471 27.9475 80.6471 28.026V29.8708C80.6471 29.9493 80.6093 30.0082 80.5525 30.0475L79.0205 30.9699C78.9638 31.0091 78.8881 31.0091 78.8314 30.9699L77.2994 30.0475C77.2427 30.0082 77.2048 29.9493 77.2048 29.8708V28.026C77.2048 27.9475 77.2427 27.8886 77.2994 27.8493L78.8314 26.9269Z"
            fill="white" />
        <path
            d="M35.5763 18.5661C35.2737 18.3894 34.9143 18.3894 34.6117 18.5661L26.6491 23.3352C26.3465 23.5119 26.1763 23.8455 26.1763 24.1988V33.7567C26.1763 34.11 26.3654 34.4436 26.6491 34.6203L34.6117 39.3894C34.9143 39.5661 35.2737 39.5661 35.5763 39.3894L43.5388 34.6203C43.8415 34.4436 44.0117 34.11 44.0117 33.7567V24.1988C44.0117 23.8455 43.8225 23.5119 43.5388 23.3352L35.5763 18.5661Z"
            fill="#DDDDDD" />
        <path
            d="M43.5577 23.3351L35.5573 18.5659C35.4816 18.5267 35.3871 18.4874 35.3114 18.4678L26.3464 34.4042C26.4221 34.5024 26.5167 34.5809 26.6112 34.6397L34.6116 39.4089C34.8386 39.5463 35.1034 39.5855 35.3492 39.507L43.7657 23.5313C43.709 23.4528 43.6334 23.3939 43.5577 23.3351Z"
            fill="#F2F2F2" />
        <path d="M34.3469 16.8193L34.2334 16.8782H34.3847L34.3469 16.8193Z" fill="url(#paint0_linear_3106_3286)" />
        <path
            d="M43.5765 34.6205C43.8035 34.4831 43.9737 34.2476 44.0493 33.9925L35.2735 18.4485C35.0465 18.4093 34.8006 18.4289 34.5926 18.5663L26.6489 23.3158L35.2167 39.527C35.3302 39.5074 35.4626 39.4682 35.5761 39.4093L43.5765 34.6205Z"
            fill="url(#paint1_linear_3106_3286)" />
        <path
            d="M43.5765 34.6205C43.8035 34.4831 43.9737 34.2476 44.0493 33.9925L35.2735 18.4485C35.0465 18.4093 34.8006 18.4289 34.5926 18.5663L26.6489 23.3158L35.2167 39.527C35.3302 39.5074 35.4626 39.4682 35.5761 39.4093L43.5765 34.6205Z"
            fill="white" />
        <path d="M44.3143 34.4634L44.2576 34.3652V34.5026L44.3143 34.4634Z" fill="url(#paint2_linear_3106_3286)" />
        <path
            d="M43.5767 34.6203L35.5952 39.3895C35.4817 39.4484 35.3682 39.4876 35.2358 39.5072L35.3871 39.8016L44.2387 34.4829V34.3652L44.0117 33.9727C43.9739 34.2474 43.8036 34.4829 43.5767 34.6203Z"
            fill="url(#paint3_linear_3106_3286)" />
        <path
            d="M43.5767 34.6203L35.5952 39.3895C35.4817 39.4484 35.3682 39.4876 35.2358 39.5072L35.3871 39.8016L44.2387 34.4829V34.3652L44.0117 33.9727C43.9739 34.2474 43.8036 34.4829 43.5767 34.6203Z"
            fill="url(#paint4_linear_3106_3286)" />
        <defs>
            <linearGradient id="paint0_linear_3106_3286" x1="25.8854" y1="16.8457" x2="44.3104" y2="16.8457"
                gradientUnits="userSpaceOnUse">
                <stop offset="0.0919165" stop-color="#6ABF4B" />
                <stop offset="0.2871" stop-color="#64B749" />
                <stop offset="0.5984" stop-color="#52A044" />
                <stop offset="0.8624" stop-color="#3F873F" />
            </linearGradient>
            <linearGradient id="paint1_linear_3106_3286" x1="25.8853" y1="28.9783" x2="44.3102" y2="28.9783"
                gradientUnits="userSpaceOnUse">
                <stop offset="0.0919165" stop-color="#6ABF4B" />
                <stop offset="0.2871" stop-color="#64B749" />
                <stop offset="0.5984" stop-color="#52A044" />
                <stop offset="0.8624" stop-color="#3F873F" />
            </linearGradient>
            <linearGradient id="paint2_linear_3106_3286" x1="25.8876" y1="34.4363" x2="44.3104" y2="34.4363"
                gradientUnits="userSpaceOnUse">
                <stop offset="0.0919165" stop-color="#6ABF4B" />
                <stop offset="0.2871" stop-color="#64B749" />
                <stop offset="0.5984" stop-color="#52A044" />
                <stop offset="0.8624" stop-color="#3F873F" />
            </linearGradient>
            <linearGradient id="paint3_linear_3106_3286" x1="25.8854" y1="36.9032" x2="44.3104" y2="36.9032"
                gradientUnits="userSpaceOnUse">
                <stop offset="0.0919165" stop-color="#6ABF4B" />
                <stop offset="0.2871" stop-color="#64B749" />
                <stop offset="0.5984" stop-color="#52A044" />
                <stop offset="0.8624" stop-color="#3F873F" />
            </linearGradient>
            <linearGradient id="paint4_linear_3106_3286" x1="46.9947" y1="21.6695" x2="34.9382" y2="45.3716"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#3F873F" />
                <stop offset="0.3296" stop-color="#3F8B3D" />
                <stop offset="0.6367" stop-color="#3E9638" />
                <stop offset="0.9341" stop-color="#3DA92E" />
                <stop offset="1" stop-color="#3DAE2B" />
            </linearGradient>
        </defs>
    </svg>
</template>
