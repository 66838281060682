<template>
    <svg width="56" height="48" viewBox="0 0 56 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3106_3216)">
            <path d="M44.8 0H56L28 47.9566L0 0H11.06H21.42L28 11.1204L34.44 0H44.8Z" fill="white" />
            <path d="M0 0L28 47.9566L56 0H44.8L28 28.7739L11.06 0H0Z" fill="white" />
            <path d="M11.0601 0L28.0001 28.9129L44.8001 0H34.4401L28.0001 11.1204L21.4201 0H11.0601Z" fill="#DDDDDD" />
        </g>
        <defs>
            <clipPath id="clip0_3106_3216">
                <rect width="56" height="48" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
