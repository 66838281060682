<template>
    <svg width="68"
         height="20"
         viewBox="0 0 68 20"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0"
              mask-type="alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="68"
              height="20">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0.131836H68V19.5217H0V0.131836Z" fill="white"/>
        </mask>
        <g mask="url(#mask0)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M66.038 2.0706C66.1833 2.0706 66.2828 2.04717 66.3352 2.00041C66.387 1.95409 66.4133 1.87952 66.4133 1.77723C66.4133 1.67485 66.3865 1.60411 66.3319 1.56491C66.2778 1.52571 66.1833 1.5061 66.0489 1.5061H65.7409V2.0706H66.038ZM67.1366 3.26139C67.4359 2.95225 67.585 2.57993 67.585 2.14389C67.585 1.70784 67.4282 1.33397 67.1141 1.0209C66.8001 0.707936 66.422 0.551764 65.979 0.551764C65.5361 0.551764 65.1564 0.710121 64.8406 1.02638C64.5249 1.34317 64.367 1.72097 64.367 2.16075C64.367 2.60007 64.5223 2.97613 64.8325 3.2892C65.1427 3.60216 65.5241 3.75834 65.9764 3.75834C66.4286 3.75834 66.8115 3.5967 67.1257 3.27233H66.475L65.9872 2.57391H65.7409V3.27233H65.1242V1.00404H66.0266C66.3855 1.00404 66.6417 1.06558 66.7947 1.18856C66.9481 1.31163 67.0246 1.50864 67.0246 1.78086C67.0246 2.11234 66.8732 2.34308 66.5706 2.47381L67.1366 3.26139ZM67.4054 0.724051C67.8015 1.11871 67.9998 1.59395 67.9998 2.1486C67.9998 2.7038 67.8026 3.17959 67.4082 3.57635C67.0138 3.97265 66.5347 4.17139 65.9699 4.17139C65.4057 4.17139 64.9283 3.97483 64.5377 3.58182C64.1472 3.18881 63.9521 2.71465 63.9521 2.16C63.9521 1.6048 64.1488 1.128 64.5431 0.729517C64.9376 0.331038 65.4149 0.131836 65.976 0.131836C66.5364 0.131836 67.0126 0.329394 67.4054 0.724051Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.89394 15.4821C6.40268 15.4821 5.05519 15.169 3.85219 14.5431C2.64919 13.9169 1.70631 13.0519 1.0238 11.9475C0.341295 10.8435 0 9.5979 0 8.2109C0 6.82389 0.344666 5.57839 1.03408 4.47442C1.7235 3.37036 2.67024 2.505 3.87332 1.87913C5.07633 1.25289 6.42382 0.939697 7.91507 0.939697C9.12548 0.939697 10.2298 1.14198 11.2291 1.54558C12.2284 1.9497 13.0725 2.5356 13.7619 3.30299L11.9887 4.89845C10.9197 3.79439 9.60379 3.24222 8.04172 3.24222C7.02869 3.24222 6.12126 3.45424 5.31893 3.87871C4.51701 4.30274 3.89101 4.89176 3.44074 5.64585C2.9904 6.39986 2.76506 7.25505 2.76506 8.2109C2.76506 9.16719 2.9904 10.0219 3.44074 10.7759C3.89101 11.53 4.51701 12.1191 5.31893 12.5435C6.12126 12.9676 7.02869 13.1796 8.04172 13.1796C9.60379 13.1796 10.9197 12.6207 11.9887 11.5034L13.7619 13.1189C13.0725 13.8866 12.2244 14.4722 11.2188 14.8762C10.2126 15.2803 9.10434 15.4821 7.89394 15.4821Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0774 13.6005C19.705 13.6005 20.2627 13.452 20.7502 13.1559C21.2376 12.8595 21.5814 12.4358 21.7814 11.884V10.6022H19.1977C17.7555 10.6022 17.0347 11.0917 17.0347 12.0702C17.0347 12.5388 17.2149 12.9113 17.5756 13.1869C17.9358 13.4625 18.4367 13.6005 19.0774 13.6005ZM19.4182 4.17139C20.994 4.17139 22.199 4.56099 23.0335 5.33974C23.8681 6.11849 24.2856 7.29369 24.2856 8.86526V15.3373H21.9218V13.9932C21.6147 14.4755 21.1777 14.8446 20.6101 15.0994C20.0426 15.3545 19.358 15.4821 18.5571 15.4821C17.7557 15.4821 17.0549 15.3405 16.4537 15.058C15.8532 14.7756 15.389 14.3828 15.0619 13.8797C14.7349 13.3761 14.5713 12.8076 14.5713 12.1737C14.5713 11.1811 14.9282 10.3851 15.643 9.78524C16.3573 9.18581 17.4821 8.88618 19.0174 8.88618H21.7815V8.72039C21.7815 7.94848 21.5579 7.3559 21.1106 6.94219C20.6632 6.52855 19.9992 6.32193 19.1176 6.32193C18.517 6.32193 17.9262 6.41866 17.3451 6.61167C16.7645 6.80461 16.2739 7.07345 15.8731 7.41765L14.8919 5.53633C15.4524 5.09501 16.1267 4.7572 16.9146 4.52289C17.7025 4.28858 18.5371 4.17139 19.4182 4.17139Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1989 13.289C31.8415 13.289 32.4195 13.149 32.9337 12.8691C33.4478 12.5886 33.8544 12.189 34.1543 11.67C34.4543 11.1507 34.6043 10.5499 34.6043 9.86672C34.6043 9.18351 34.4543 8.58223 34.1543 8.06281C33.8544 7.54392 33.4478 7.1442 32.9337 6.86432C32.4195 6.58392 31.8415 6.44395 31.1989 6.44395C30.5566 6.44395 29.9782 6.58392 29.464 6.86432C28.9503 7.1442 28.5433 7.54392 28.2434 8.06281C27.9439 8.58223 27.7939 9.18351 27.7939 9.86672C27.7939 10.5499 27.9439 11.1507 28.2434 11.67C28.5433 12.189 28.9503 12.5886 29.464 12.8691C29.9782 13.149 30.5566 13.289 31.1989 13.289ZM37.2381 0.131836V15.3385H34.6682V13.9245C34.2252 14.4433 33.6792 14.8331 33.0295 15.0924C32.3803 15.352 31.6629 15.4821 30.8772 15.4821C29.7781 15.4821 28.7891 15.2495 27.9112 14.785C27.0333 14.3205 26.3443 13.6611 25.8445 12.8073C25.3448 11.9536 25.0952 10.9733 25.0952 9.86673C25.0952 8.75998 25.3448 7.78287 25.8445 6.93586C26.3443 6.08885 27.0333 5.43308 27.9112 4.96868C28.7891 4.50376 29.7781 4.27152 30.8772 4.27152C31.6339 4.27152 32.3265 4.39484 32.9548 4.64057C33.583 4.8863 34.1186 5.25534 34.561 5.74725V0.131836H37.2381Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M44.9607 13.289C45.6029 13.289 46.1813 13.149 46.695 12.8691C47.2092 12.5886 47.6162 12.189 47.9162 11.67C48.2156 11.1507 48.3656 10.5499 48.3656 9.86672C48.3656 9.18351 48.2156 8.58223 47.9162 8.06281C47.6162 7.54392 47.2092 7.1442 46.695 6.86432C46.1813 6.58392 45.6029 6.44395 44.9607 6.44395C44.3179 6.44395 43.74 6.58392 43.2258 6.86432C42.7116 7.1442 42.3051 7.54392 42.0051 8.06281C41.7052 8.58223 41.5552 9.18351 41.5552 9.86672C41.5552 10.5499 41.7052 11.1507 42.0051 11.67C42.3051 12.189 42.7116 12.5886 43.2258 12.8691C43.74 13.149 44.3179 13.289 44.9607 13.289ZM50.9998 0.131836V15.3385H48.4299V13.9245C47.9875 14.4433 47.4415 14.8331 46.7917 15.0924C46.142 15.352 45.4246 15.4821 44.6394 15.4821C43.5398 15.4821 42.5513 15.2495 41.6729 14.785C40.795 14.3205 40.1059 13.6611 39.6063 12.8073C39.1065 11.9536 38.8569 10.9733 38.8569 9.86673C38.8569 8.75998 39.1065 7.78287 39.6063 6.93586C40.1059 6.08885 40.795 5.43308 41.6729 4.96868C42.5513 4.50376 43.5398 4.27152 44.6394 4.27152C45.3962 4.27152 46.0888 4.39484 46.7165 4.64057C47.3448 4.8863 47.8803 5.25534 48.3228 5.74725V0.131836H50.9998Z" fill="white"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M63.1429 4.97925L58.075 17.0296C57.602 18.2408 57.0275 19.0935 56.3518 19.588C55.676 20.0824 54.8586 20.3296 53.899 20.3296C53.3585 20.3296 52.8245 20.2388 52.2973 20.0581C51.7706 19.8769 51.3381 19.6265 51 19.3059L52.0135 17.3846C52.2568 17.6213 52.5441 17.8093 52.8751 17.9483C53.2061 18.0877 53.541 18.1575 53.8787 18.1575C54.3248 18.1575 54.6932 18.0391 54.9836 17.8024C55.2741 17.5657 55.5411 17.1686 55.7844 16.6118L55.9665 16.1733L51.2434 4.97925H53.8787L57.2846 13.2284L60.7103 4.97925H63.1429Z" fill="white"/>
        </g>
    </svg>
</template>
