

    import { defineComponent } from 'vue'
    import Alibaba from './Logos/Technology/Alibaba.vue'
    import AWS from './Logos/Technology/AWS.vue'
    import Azure from './Logos/Technology/Azure.vue'
    import Caddy from './Logos/Technology/Caddy.vue'
    import Docker from './Logos/Technology/Docker.vue'
    import Flutter from './Logos/Technology/Flutter.vue'
    import GoogleCloud from './Logos/Technology/GoogleCloud.vue'
    import Graphql from './Logos/Technology/Graphql.vue'
    import Laravel from './Logos/Technology/Laravel.vue'
    import Node from './Logos/Technology/Node.vue'
    import Nova from './Logos/Technology/Nova.vue'
    import Tailwind from './Logos/Technology/Tailwind.vue'
    import Tencent from './Logos/Technology/Tencent.vue'
    import ThreeJS from './Logos/Technology/ThreeJS.vue'
    import Traefik from './Logos/Technology/Traefik.vue'
    import Typescript from './Logos/Technology/Typescript.vue'
    import Vue from './Logos/Technology/Vue.vue'
    import React from './Logos/Technology/React.vue'
    import WebGL from './Logos/Technology/WebGL.vue'
    import Wechat from './Logos/Technology/Wechat.vue'

    export default defineComponent({
        name: 'TechnologyWall',
        components: {
            Alibaba,
            AWS,
            Azure,
            Caddy,
            Docker,
            Flutter,
            GoogleCloud,
            Graphql,
            Laravel,
            Node,
            Nova,
            React,
            Tencent,
            Traefik,
            ThreeJS,
            Typescript,
            Vue,
            WebGL,
            Wechat,
            Tailwind
        },
        setup() {

            const logos = [
                { logo: React, name: 'react' },
                { logo: Vue, name: 'vue' },
                { logo: Typescript, name: 'typescript' },
                { logo: Wechat, name: 'wechat' },
                { logo: Flutter, name: 'flutter' },
                { logo: Tailwind, name: 'tailwind' },
                { logo: ThreeJS, name: 'threejs' },
                { logo: WebGL, name: 'webgl' },
                { logo: Laravel, name: 'laravel' },
                { logo: Nova, name: 'nova' },
                { logo: Node, name: 'node' },
                { logo: Graphql, name: 'graphql' },
                { logo: Docker, name: 'docker' },
                { logo: Caddy, name: 'caddy' },
                { logo: Traefik, name: 'traefik' },
                { logo: AWS, name: 'aws' },
                { logo: Alibaba, name: 'alibaba' },
                { logo: Azure, name: 'azure' },
                { logo: GoogleCloud, name: 'googlecloud' },
                { logo: Tencent, name: 'tencent' }
            ]

            return {
                logos
            }

        }
    })

