import { defineComponent, onUnmounted, ref, watch } from 'vue'
// import { read } from 'fs-extra'

type MarginType = { top: number, bottom: number }

export function stickElementOnScroll(margin: MarginType = { top: 0, bottom: 0 }, useParallax = false, elements: HTMLElement[]) {

    const ready = ref(false)
    const wrapperReference = ref<HTMLElement>()
    const stickyElementReference = ref<HTMLElement | ReturnType<typeof defineComponent>>()

    const offsetStyle = ref<Partial<CSSStyleDeclaration>>({
        position: 'relative',
        top: '0px'
    })

    const isSticky = ref<boolean>()

    function onScroll() {

        if (ready.value === false) {

            return

        }

        if (wrapperReference.value === undefined || stickyElementReference.value === undefined) {

            throw Error('Invalid element. Please set wrapper and sticky element reference correctly.')

        }

        let stickyElement: HTMLElement

        if (stickyElementReference.value instanceof HTMLElement) {

            stickyElement = stickyElementReference.value

        } else {

            stickyElement = stickyElementReference.value.$el ?? stickyElementReference.value

        }

        const wrapperTop = wrapperReference.value.offsetTop
        const wrapperHeight = wrapperReference.value.offsetHeight
        const percentageScrolled = (window.pageYOffset - wrapperTop) / (wrapperHeight - margin.bottom)
        const scrollableHeight = window.innerHeight - margin.top - margin.bottom - stickyElement.offsetHeight
        const positionOfSticky = scrollableHeight * percentageScrolled

        const positionOfThemeChange = ((window.innerHeight / 100) * 35)
        const bottomOfElement = elements[1].getBoundingClientRect().bottom

        if (bottomOfElement <= positionOfThemeChange) {

            offsetStyle.value.position = 'relative'
            offsetStyle.value.top = `${ elements[2].offsetTop - elements[0].offsetTop }px`
            isSticky.value = false

        } else if (window.pageYOffset > wrapperTop) {

            offsetStyle.value.position = 'fixed'
            offsetStyle.value.top = useParallax ? `${ margin.top + positionOfSticky }px` : `${ margin.top }px`
            isSticky.value = true

        } else {

            offsetStyle.value.position = 'relative'
            offsetStyle.value.top = '0px'
            isSticky.value = false

        }

    }

    /**
     * Only start listening to events etc once elements are ready
     */
    watch([ wrapperReference, stickyElementReference ], ([ wrapper, stickyElement ]) => {

        ready.value = !!wrapper && !!stickyElement

        if (ready.value) {

            onScroll()
            window.addEventListener('scroll', onScroll, false)

        } else {

            window.removeEventListener('scroll', onScroll, false)

        }

    })

    onUnmounted(() => window.removeEventListener('scroll', onScroll, false))

    return {
        wrapperReference,
        stickyElementReference,
        offsetStyle,
        isSticky
    }

}
