import anime, { AnimeInstance } from 'animejs'

let runningInstance: AnimeInstance | null = null

function stopAnimationIfRunning() {

    if (runningInstance) {

        runningInstance.pause()
        anime.remove(runningInstance)
        runningInstance = null

    }

    removeInteractionListeners()

}

function addInteractionListeners() {

    window.addEventListener('wheel', stopAnimationIfRunning)
    window.addEventListener('keypress', stopAnimationIfRunning)
    window.addEventListener('touchstart', stopAnimationIfRunning)
    window.addEventListener('resize', stopAnimationIfRunning)

}

function removeInteractionListeners() {

    window.removeEventListener('wheel', stopAnimationIfRunning)
    window.removeEventListener('keypress', stopAnimationIfRunning)
    window.removeEventListener('touchstart', stopAnimationIfRunning)
    window.removeEventListener('resize', stopAnimationIfRunning)

}

export function useScrollToElement(elements: HTMLElement[]) {

    return {
        scrollToElement(index: number) {

            const element = elements[index]

            stopAnimationIfRunning()
            addInteractionListeners()

            runningInstance = anime({
                targets: document.documentElement,
                scrollTop: [ window.scrollY, element.offsetTop ],
                easing: 'easeOutQuart',
                complete: () => removeInteractionListeners()
            })

        }

    }

}
