<template>
    <svg width="87" height="36" viewBox="0 0 87 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_3111_3133)">
            <path d="M84.564 23.899H83.9729V25.4213H83.4556V23.899H82.8645V23.46H84.5616V23.899H84.564Z" fill="white" />
            <path
                d="M84.7927 23.46H85.5531L85.8939 24.7675H85.8987L86.2395 23.46H86.9999V25.4213H86.5184V23.9344H86.5113L86.0965 25.4213H85.7032L85.2861 23.9344H85.2814V25.4213H84.7975V23.46H84.7927Z"
                fill="white" />
            <path
                d="M64.1369 25.311C63.4481 25.6061 62.7068 25.7618 61.9559 25.7666C60.7975 25.7666 59.7559 25.5659 58.8311 25.1671C57.9396 24.7918 57.1411 24.2277 56.4904 23.5173C55.8445 22.7974 55.3463 21.9596 55.0293 21.0485C54.6837 20.0667 54.5121 19.0306 54.5216 17.9921C54.5121 16.9324 54.6837 15.8774 55.0269 14.8719C55.3439 13.9491 55.8373 13.0971 56.4833 12.3607C57.1292 11.6385 57.9253 11.0649 58.8192 10.6802C59.744 10.2743 60.7856 10.0713 61.9416 10.0713C62.7068 10.0713 63.4647 10.1869 64.1917 10.4182C64.9044 10.6401 65.5718 10.9847 66.1653 11.4378C67.4095 12.389 68.189 13.8146 68.3153 15.3628H65.1404C64.9425 14.525 64.5612 13.8948 63.9963 13.477C63.4314 13.0593 62.7473 12.8492 61.9416 12.8492C61.1908 12.8492 60.5568 12.9932 60.0348 13.2788C59.5223 13.5573 59.0861 13.9538 58.7644 14.4353C58.4307 14.938 58.1899 15.4974 58.0564 16.0851C57.9063 16.7105 57.8324 17.3525 57.8348 17.9945C57.8348 18.6152 57.9087 19.2312 58.0564 19.833C58.1947 20.4066 58.4354 20.9494 58.7667 21.4403C59.0933 21.9147 59.5271 22.3065 60.0372 22.5827C60.5592 22.8706 61.1956 23.0122 61.9464 23.0122C63.05 23.0122 63.9009 22.7361 64.5016 22.1862C65.1022 21.6362 65.4526 20.8385 65.5527 19.7953H62.2062V17.3265H68.5489V25.422H66.437L66.0986 23.7226C65.5051 24.4803 64.8496 25.0089 64.1274 25.3087L64.1369 25.311Z"
                fill="white" />
            <path d="M73.9452 10.4463V22.6367H81.3151V25.4146H70.6296V10.4486H73.9452V10.4463Z" fill="white" />
            <path
                d="M58.2614 27.2767C53.1868 30.3332 45.5856 32.0726 37.1049 32.0726C21.8263 32.0703 9.43652 25.7709 9.43652 17.9988C9.43652 10.2267 21.8239 3.92736 37.1025 3.92736C45.6118 3.92736 53.2488 5.69986 58.321 8.77519C52.784 3.62289 42.8517 5.18363e-07 31.494 5.18363e-07C14.0988 -0.00235967 0 8.05533 0 17.9941C0 27.9352 14.0988 35.9953 31.4892 35.9976C42.7992 35.9976 52.7077 32.3889 58.2614 27.2767Z"
                fill="white" />
            <path
                d="M30.388 9.28027L27.4705 21.7775L23.95 9.28027H21.2756L17.7599 21.7775L14.8352 9.28027H12.0798L16.4084 25.5703H18.9922L22.6342 12.7757L26.231 25.5703H28.8244L33.3031 9.28027H30.388Z"
                fill="white" />
            <path
                d="M40.5896 16.1364C40.2035 15.5889 39.6863 15.1451 39.0832 14.8454C38.4254 14.5338 37.7055 14.3804 36.9762 14.3922C36.0919 14.3922 35.2934 14.5504 34.6069 14.8643C33.9586 15.1593 33.3913 15.6054 32.9527 16.1624C32.128 17.2079 31.6918 18.6807 31.6918 20.4202C31.687 21.1518 31.7991 21.8811 32.0255 22.5774C32.2281 23.2099 32.557 23.7976 32.9908 24.305C33.8751 25.3223 35.1384 25.8604 36.6401 25.8604C37.429 25.8604 38.0726 25.7802 38.6089 25.6102C38.976 25.4946 39.324 25.3246 39.6434 25.1075C39.9771 24.888 40.2774 24.626 40.5372 24.3263C40.7684 24.0619 40.9686 23.774 41.1378 23.4672C41.4453 22.9102 41.5931 22.3886 41.6265 22.1148L41.6479 21.9307H39.5003L39.4884 22.0817C39.4336 22.7733 38.5183 24.0077 36.8856 24.0077C34.5545 24.0077 33.9967 22.3366 33.9586 20.8662H41.7695V20.7034C41.779 19.8372 41.6813 18.971 41.4787 18.1284C41.3095 17.4133 41.0091 16.7383 40.5896 16.1317V16.1364ZM36.7306 16.3111C38.4611 16.3111 39.3835 17.2339 39.5552 19.1291H33.9609C34.042 17.5856 35.2791 16.3111 36.7306 16.3111Z"
                fill="white" />
            <path
                d="M52.5813 17.7039C52.3906 17.0785 52.0808 16.4932 51.6708 15.981C51.2727 15.4877 50.7698 15.0912 50.1954 14.8221C49.5756 14.5366 48.8987 14.3926 48.2146 14.402C47.5877 14.402 46.9704 14.5507 46.4126 14.8292C45.9622 15.0629 45.5617 15.3839 45.2375 15.7709V10.7744H43.0947V25.5799H45.1327V24.5697C45.3353 24.8341 45.5736 25.0677 45.8454 25.2613C46.4222 25.6719 47.1372 25.8796 47.9691 25.8796C48.8629 25.8796 49.6614 25.6814 50.3408 25.292C50.9629 24.9261 51.4872 24.4163 51.8638 23.805C52.2357 23.2008 52.5074 22.54 52.6647 21.8508C52.8197 21.1876 52.8983 20.5102 52.8983 19.8305C52.9031 19.1106 52.7982 18.3955 52.5861 17.7087L52.5813 17.7039ZM50.6601 20.1066C50.6601 21.2678 50.4242 22.2426 49.9785 22.9223C49.5017 23.6469 48.8129 24.0151 47.9262 24.0151C46.6462 24.0151 45.1517 23.1277 45.1517 20.6306C45.1517 19.4363 45.3019 18.5135 45.6094 17.8149C46.0527 16.8071 46.7988 16.3185 47.8857 16.3185C49.0441 16.3185 49.8188 16.7693 50.2549 17.6921C50.6149 18.4568 50.6601 19.3844 50.6601 20.1066Z"
                fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_3111_3133">
                <rect width="87" height="36" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
