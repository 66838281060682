import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-wrap items-center justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logos, (logo, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(["flex justify-center items-center mb-12 h-20 w-40 md:w-56 xl:w-64 tech-logo", `--${logo.name}`])
      }, [
        (_openBlock(), _createBlock(_resolveDynamicComponent(logo.logo)))
      ], 2))
    }), 128))
  ]))
}