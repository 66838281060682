

    import { computed, ComputedRef, defineComponent } from 'vue'
    import Column from '../components/Grid/Column.vue'
    import Grid from '../components/Grid/Grid.vue'
    import { stickElementOnScroll } from '../compositions/behaviors/StickElementOnScroll'
    import { useComponentIsOnScreen } from '../compositions/behaviors/UseComponentIsOnScreen'
    import { useContainerScrollProgress } from '../compositions/behaviors/UseContainerScrollProgress'
    import { useScrollToElement } from '../compositions/behaviors/UseScrolltoElement'
    import { useLoopReferences } from '../compositions/UseLoopReferences'
    import { useTranslation } from '../compositions/UseTranslation'
    import { Theme } from '../enums/Theme'

    type MenuItem = { title: string, theme: Theme, key: string }

    export default defineComponent({
        name: 'SliderBlock',
        components: {
            Grid,
            Column
        },
        setup() {

            const { whatWeDo } = useTranslation()

            const menuItems: ComputedRef<MenuItem[]> = computed(() => {

                const data = whatWeDo.value

                return [
                    {
                        title: data.what_service_01,
                        key: 'strategy',
                        theme: Theme.Dark
                    },
                    {
                        title: data.what_service_02,
                        key: 'design',
                        theme: Theme.Green
                    },
                    {
                        title: data.what_service_03,
                        key: 'technology',
                        theme: Theme.Default
                    }
                ]

            })

            const { references, setReference } = useLoopReferences<HTMLDivElement>()

            const { wrapperReference, stickyElementReference, offsetStyle } = stickElementOnScroll({
                top: 96,
                bottom: 0
            }, true, references.value)

            const { activeElementIndex: activeMenu } = useComponentIsOnScreen(references.value)
            const { scrollToElement } = useScrollToElement(references.value)
            const { progress } = useContainerScrollProgress(wrapperReference, references.value, { top: 50, bottom: 0 })

            return {
                activeMenu,
                setReference,
                wrapperReference,
                stickyElementReference,
                offsetStyle,
                progress,
                items: menuItems,
                setActiveMenu(menuIndex: number) {

                    scrollToElement(menuIndex)

                }
            }

        }
    })

