<template>
    <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M32.3069 14.9485C21.6479 15.2872 18.4799 22.9015 17.7539 27.0265C18.6999 25.4974 21.6809 22.1425 26.0699 22.1425C31.1849 22.1425 35.0789 27.8515 37.4549 29.6335C40.3415 31.7985 46.142 34.6571 53.7569 32.3395C59.8289 30.4915 62.6449 24.0454 63.2609 20.9875C60.3239 24.9805 55.8689 27.3788 50.3579 24.5515C46.5629 22.6045 43.7249 14.5855 32.3069 14.9485Z"
            fill="white" />
        <path
            d="M17.2261 33.0656C6.5671 33.4044 3.3991 41.0186 2.6731 45.1436C3.61909 43.6146 6.6001 40.2597 10.9891 40.2597C16.1041 40.2597 19.9981 45.9687 22.3741 47.7507C25.2607 49.9157 31.0612 52.7743 38.6761 50.4567C44.7481 48.6087 47.5641 42.1626 48.1801 39.1047C45.2431 43.0977 40.7881 45.496 35.2771 42.6686C31.4821 40.7216 28.6441 32.7027 17.2261 33.0656Z"
            fill="white" />
    </svg>
</template>
