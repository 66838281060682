<template>
    <svg width="57" height="53" viewBox="0 0 57 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3109_3462)">
<path d="M28.5001 31.8093C31.306 31.8093 33.5805 29.4322 33.5805 26.4999C33.5805 23.5676 31.306 21.1904 28.5001 21.1904C25.6943 21.1904 23.4197 23.5676 23.4197 26.4999C23.4197 29.4322 25.6943 31.8093 28.5001 31.8093Z" fill="white"/>
<path d="M28.4999 37.3779C43.5556 37.3779 55.7608 32.5077 55.7608 26.5C55.7608 20.4923 43.5556 15.6221 28.4999 15.6221C13.4441 15.6221 1.23901 20.4923 1.23901 26.5C1.23901 32.5077 13.4441 37.3779 28.4999 37.3779Z" stroke="white"/>
<path d="M19.4856 31.9385C27.0135 45.5649 37.1518 54.1762 42.1302 51.1724C47.1086 48.1685 45.0419 34.687 37.514 21.0606C29.9861 7.43412 19.8478 -1.1772 14.8694 1.82665C9.89095 4.83051 11.9577 18.312 19.4856 31.9385Z" stroke="white"/>
<path d="M19.4857 21.0606C11.9578 34.687 9.89107 48.1686 14.8695 51.1724C19.8479 54.1763 29.9862 45.5649 37.5141 31.9385C45.042 18.312 47.1087 4.83052 42.1303 1.82667C37.1519 -1.17719 27.0136 7.43413 19.4857 21.0606Z" stroke="white"/>
</g>
<defs>
<clipPath id="clip0_3109_3462">
<rect width="57" height="53" fill="white"/>
</clipPath>
</defs>
</svg>
</template>
