import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { ref: "wrapperReference" }
const _hoisted_2 = { class: "slider-block__list py-6 space-y-6 font-mabry-pro text-xl" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Grid, {
      columns: 6,
      class: "absolute right-0 left-0 my-24 hidden md:block"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          ref: "stickyElementReference",
          span: 1,
          style: _normalizeStyle(_ctx.offsetStyle)
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: "border-pink-400 0 border-l absolute h-full",
              style: _normalizeStyle({ height: `calc(${ _ctx.progress }%)`, left: '-20px' })
            }, null, 4),
            _createElementVNode("ul", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: item.key,
                  class: _normalizeClass(["slider-block__item cursor-pointer font-bold", [ index === _ctx.activeMenu ? 'text-pink-400' : 'text-pink-300' ]]),
                  onClick: ($event: any) => (_ctx.setActiveMenu(index))
                }, _toDisplayString(item.title), 11, _hoisted_3))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["style"])
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        ref_for: true,
        ref: _ctx.setReference,
        key: index
      }, [
        _renderSlot(_ctx.$slots, item.key)
      ]))
    }), 128))
  ], 512))
}