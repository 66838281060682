<template>
    <svg width="83" height="58" viewBox="0 0 83 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M57.1754 54.8487C57.1754 54.8086 57.1353 54.7684 57.055 54.8086C56.3328 55.6511 55.1692 56.2931 53.8051 56.2931C51.7187 56.2931 50.3545 54.8487 50.3545 52.6018C50.3545 50.3148 51.6384 48.9105 53.7248 48.9105C54.9285 48.9105 56.1723 49.4722 56.9347 50.2747C56.9748 50.3148 57.055 50.2747 57.055 50.2345V49.071C56.2124 48.3086 54.9686 47.8271 53.7248 47.8271C52.4409 47.8271 51.2773 48.3086 50.515 49.1512C49.7526 49.9938 49.3113 51.1975 49.3113 52.6018C49.3113 55.4505 51.1168 57.3764 53.7649 57.3764C55.0489 57.3764 56.2927 56.895 57.1353 56.0925V54.8487H57.1754Z"
            fill="white" />
        <path d="M0 48.0674V49.0705H3.1296V57.1352H4.21292V49.0705H7.38264V48.0674H0Z" fill="white" />
        <path
            d="M81.2492 51.1579C80.6875 50.5561 79.7245 50.3153 79.0826 50.3153C78.1597 50.3153 77.3573 50.6363 76.7955 51.2783C76.1937 51.8801 75.9128 52.803 75.9128 53.806C75.9128 56.2134 77.5178 57.2967 79.1227 57.2967C80.0455 57.2967 80.8079 56.9758 81.2893 56.3739V57.1362H82.3727V48.0283H81.2492V51.1579ZM81.2492 53.806C81.2492 55.0499 80.527 56.2937 79.1628 56.2937C78.1196 56.2937 76.9962 55.5313 76.9962 53.8462C76.9962 52.6023 77.6381 51.2783 79.0826 51.2783C80.6875 51.3184 81.2492 52.6425 81.2492 53.806Z"
            fill="white" />
        <path d="M59.7032 48.1084H58.6199V57.1762H59.7032V48.1084Z" fill="white" />
        <path
            d="M26.9225 55.4502C26.9225 55.4101 26.8824 55.37 26.8021 55.4101C26.0799 56.052 25.0367 56.2928 24.5954 56.2928C23.1509 56.2928 22.188 55.3298 22.188 53.8052C22.188 52.2805 23.1509 51.3175 24.5954 51.3175C25.0367 51.3175 26.0398 51.4379 26.8021 52.0799C26.8422 52.12 26.8824 52.0799 26.8824 52.0397V51.0367C26.441 50.7558 25.4781 50.3145 24.5151 50.3145C22.509 50.3145 21.1448 51.7188 21.1448 53.8052C21.1448 55.8916 22.509 57.2959 24.5151 57.2959C25.3577 57.2959 26.1602 57.0551 26.8824 56.5736L26.9225 55.4502Z"
            fill="white" />
        <path
            d="M12.6788 55.2496C12.6788 55.2095 12.5986 55.1693 12.5585 55.2095C12.077 55.8113 10.9134 56.373 10.2313 56.373C9.54924 56.373 8.90727 56.1724 8.58629 55.7712C8.18506 55.3298 7.94432 54.728 7.94432 54.1261H13.0399V54.0459C13.0399 52.6015 12.7992 51.799 12.1572 51.157C11.6356 50.6354 10.9134 50.3145 10.0307 50.3145C8.14494 50.3145 6.90112 51.6786 6.90112 53.765C6.94125 55.9317 8.22518 57.2557 10.2313 57.2557C11.1943 57.2557 11.9967 56.9348 12.7591 56.2928L12.6788 55.2496ZM11.9566 53.2033H7.9042V53.1632C7.94432 52.6015 8.18506 52.12 8.62641 51.7188C8.98752 51.4379 9.469 51.2774 9.91035 51.2774C11.1542 51.2774 11.9165 51.9996 11.9566 53.2033Z"
            fill="white" />
        <path
            d="M73.5857 53.886C73.5857 55.6915 72.5425 56.3335 71.6196 56.3335C70.7771 56.3335 69.8141 55.7316 69.8141 54.6082V50.5156H68.811V54.6082C68.811 56.2131 69.9345 57.3365 71.5795 57.3365C72.5024 57.3365 73.1844 57.0557 73.6659 56.4538V57.176H74.6289V50.5156H73.6258L73.5857 53.886Z"
            fill="white" />
        <path
            d="M17.2932 50.3145C16.4507 50.3145 15.7686 50.5953 15.2871 51.1169V50.5151H14.2439V57.1354H15.2871V53.3237C15.2871 52.7218 15.4877 52.1601 15.8087 51.799C16.2099 51.4379 16.6513 51.2774 17.213 51.2774C18.0556 51.3175 18.858 51.8391 18.858 53.0027V57.0551H19.9414V53.0027C19.9414 52.1601 19.6605 51.478 19.179 51.0367C18.7377 50.5552 18.0556 50.3145 17.2932 50.3145Z"
            fill="white" />
        <path
            d="M33.7837 55.2496C33.7837 55.2095 33.7034 55.1693 33.6633 55.2095C33.1818 55.8113 32.0182 56.373 31.3362 56.373C30.6541 56.373 30.0121 56.1724 29.6911 55.7712C29.2899 55.3298 29.0491 54.728 29.0491 54.1261H34.1448V54.0459C34.1448 52.6015 33.904 51.799 33.2621 51.157C32.7003 50.6354 31.9781 50.3145 31.0954 50.3145C29.2096 50.3145 27.9658 51.6786 27.9658 53.765C28.0059 55.9317 29.2899 57.2557 31.296 57.2557C32.259 57.2557 33.0614 56.9348 33.8238 56.2928L33.7837 55.2496ZM33.0614 53.2033H29.009V53.1632C29.0491 52.6015 29.2899 52.12 29.7312 51.7188C30.0923 51.4379 30.5738 51.2774 31.0152 51.2774C32.2189 51.2774 32.9812 51.9996 33.0614 53.2033Z"
            fill="white" />
        <path
            d="M38.3577 50.3145C37.5151 50.3145 36.833 50.5953 36.3515 51.1169V50.5151H35.3083V57.1354H36.3114V53.3237C36.3114 52.7218 36.512 52.1601 36.833 51.799C37.2343 51.4379 37.6756 51.2774 38.2373 51.2774C39.0799 51.3175 39.8824 51.8391 39.8824 53.0027V57.0551H40.9256V53.0027C40.9256 52.1601 40.6447 51.478 40.1632 51.0367C39.8021 50.5552 39.1602 50.3145 38.3577 50.3145Z"
            fill="white" />
        <path
            d="M44.4563 57.216C45.0983 57.1759 45.8205 56.9352 45.8205 56.9352V55.9321C45.8205 55.892 45.7803 55.8519 45.7402 55.892C45.4594 55.9722 44.8976 56.1728 44.6168 56.1728C44.4964 56.213 44.4162 56.213 44.3359 56.1327C44.2958 56.0926 44.2958 56.0525 44.2958 55.9321V51.4784H46.2217V50.5155H44.2958V48.71H43.2125V50.4754H42.1292V51.4383H43.2125V56.0124C43.2125 56.4136 43.3328 56.7346 43.5736 56.9352C43.7742 57.1358 44.0952 57.216 44.4563 57.216Z"
            fill="white" />
        <path
            d="M64.3976 50.3145C62.4717 50.3145 61.1877 51.7188 61.1877 53.8052C61.1877 55.8514 62.4717 57.2959 64.3976 57.2959C66.3235 57.2959 67.6074 55.8916 67.6074 53.8052C67.6476 51.7589 66.3235 50.3145 64.3976 50.3145ZM62.1507 53.8052C62.1507 52.3206 63.0334 51.3577 64.3575 51.3577C65.6815 51.3577 66.5642 52.3607 66.5642 53.8453C66.5642 55.3298 65.6815 56.3329 64.3575 56.3329C63.0735 56.2928 62.1507 55.2897 62.1507 53.8052Z"
            fill="white" />
        <path
            d="M66.1228 18.898C63.7154 16.4505 60.3852 14.8857 56.6537 14.8857C53.4439 14.8857 50.6754 16.0092 48.2279 17.9752C47.1446 18.8178 46.0612 19.8209 44.6569 21.1851C43.9347 21.827 23.9133 41.3268 23.9133 41.3268C24.9967 41.4873 26.401 41.4873 27.725 41.5274C28.9287 41.5274 51.9995 41.5274 52.9624 41.5274C54.8883 41.5274 56.1722 41.5274 57.5364 41.4472C60.666 41.2064 63.595 40.083 65.9623 37.7559C71.2184 32.58 71.2986 24.1542 66.1228 18.898ZM62.2709 34.0244C61.2277 35.0275 59.4222 36.1911 56.4531 36.3114C55.0488 36.3516 53.4439 36.3516 52.6815 36.3516H36.5922C42.41 30.6942 47.5458 25.719 48.1075 25.1572C48.6291 24.6356 49.8328 23.5122 50.876 22.5894C53.163 20.5832 55.1692 20.1419 56.6136 20.182C58.8605 20.2221 60.9068 21.1449 62.3913 22.5894C65.5209 25.7591 65.4808 30.8948 62.2709 34.0244Z"
            fill="white" />
        <path
            d="M33.7838 17.6542C31.4165 15.8888 28.7684 14.8857 25.7993 14.8857C22.0679 14.8857 18.7377 16.4104 16.3303 18.898C11.1945 24.1542 11.2347 32.58 16.5309 37.7559C18.6574 39.8423 21.2253 40.9657 23.9537 41.3669L29.0894 36.3516C28.2468 36.3516 27.0832 36.3114 25.9999 36.3114C22.9907 36.2312 21.1852 35.0676 20.1821 34.0244C16.9722 30.8948 16.892 25.7591 20.0216 22.5894C21.466 21.1048 23.5524 20.182 25.7993 20.182C27.2036 20.182 29.1295 20.5832 31.2962 22.3887C32.3394 23.2313 34.6264 25.2776 35.6295 26.1603C35.6696 26.2004 35.7498 26.2004 35.7899 26.1603L39.3609 22.6696C39.401 22.6295 39.401 22.5091 39.3609 22.5091C37.6356 20.8641 35.2282 18.7376 33.7838 17.6542Z"
            fill="white" />
        <path
            d="M58.0583 11.9165C55.5707 4.97526 48.9504 0 41.2066 0C32.2592 0 24.8766 6.54005 23.5525 15.0461C24.2747 14.9258 24.9969 14.8856 25.7593 14.8856C26.8426 14.8856 27.8457 15.006 28.7685 15.2468C28.8086 15.2468 28.8086 15.2468 28.8086 15.2468C30.0123 9.50916 35.1079 5.21599 41.1665 5.21599C46.222 5.21599 50.6355 8.26534 52.6417 12.5585C52.6818 12.5986 52.7219 12.6388 52.8022 12.6388C54.3269 12.2375 56.0923 11.9968 57.8577 12.1172C58.0182 12.1573 58.0583 12.077 58.0583 11.9165Z"
            fill="white" />
    </svg>
</template>
